<style>

</style>
<script>
import { VclList } from 'vue-content-loading';

export default {
  props: ['cftools_id'],
  components: {
    VclList,
	  TracesNav: () => import('@/components/lazy/profile/components/traces/Nav.vue'),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    }
  },
  mounted() {

  },
  data() {
    return {
      ready: true,
      error: false
    }
  }
};
</script>

<template>
  <div class="margin-right: 1rem;">
    <template v-if="ready">
      <TracesNav :cftools_id="cftools_id"/>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <div class="row justify-content-center">
            <div class="col-8">
              <div class="card">
                <div class="card-body text-center">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" width="400px"></VclList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
